// Copyright (C) 2020 Outright Mental

@import "palette";

#clock {
  @include display_font($clock-color);

  .time {
    letter-spacing: 0.05em;
    font-size: 36pt;
    padding: 5px 0;
    margin-bottom: 0;
    @media only screen and (min-width: $screen-sm) {
      font-size: 28pt;
      margin-bottom: 0;
    }
    @media only screen and (min-width: $screen-md) {
      font-size: 34pt;
      margin-bottom: 0;
    }
    @media only screen and (min-width: $screen-lg) {
      font-size: 48pt;
    }
    @media only screen and (min-width: $screen-xl) {
      font-size: 60pt;
    }
  }

  .date {
    letter-spacing: 0.1em;
    font-size: 18pt;
    margin-bottom: 0;
    @media only screen and (min-width: $screen-sm) {
      font-size: 12pt;
      margin-bottom: 0;
    }
    @media only screen and (min-width: $screen-md) {
      font-size: 16pt;
      margin-bottom: 0;
    }
    @media only screen and (min-width: $screen-lg) {
      font-size: 22pt;
    }
    @media only screen and (min-width: $screen-xl) {
      font-size: 26pt;
    }
  }
}

/* latin */
@font-face {
  font-family: 'Share Tech Mono';
  font-style: normal;
  font-weight: 400;
  src: url(ShareTechMono.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}