// Copyright (C) 2020 Outright Mental

@import "palette";

#app {
  text-align: center;
  @include display_font($app-color);
}

#agendar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 0;
  padding: 0 0;
  display: flex;
  flex-direction: column;
  @include body_background;
  @media only screen and (min-width: $screen-sm) {
    flex-direction: row !important;
  }
}

#agendar-calendar {
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow-y: auto;
  padding-top: 1em;
  @media only screen and (min-width: $screen-sm) {
    padding-right: 1em;
    flex-basis: 62%;
  }
}

#agendar-clock {
  padding-top: 1.5em;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  @media only screen and (min-width: $screen-sm) {
    padding-top: 2em;
    flex-basis: 38%;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin-bottom: 1em;
  margin-top: 0;
  @media only screen and (min-width: $screen-md) {
    margin-bottom: 0;
  }
}

p.error {
  color: #ff0000;
}

sup.tiny {
  font-size: 0.2em;
  vertical-align: top;
  /* margin-top: 2rem; */
  /* padding-top: 2rem; */
  top: 0.8em;
  position: relative;
}

.ui-button {
  font-size: 10pt;
  position: absolute;
  background: transparent;
  padding: 1em 1em;
  border: 0 none;
  cursor: pointer;
  opacity: 0.38;
}

.ui-button.lit {
  opacity: 0.8;
}

.ui-button:hover {
  opacity: 1.0;
}

#menu-button {
  z-index: $ui-z-index;
  top: 0;
  right: 0;
}

.menu-item {
  color: #ffffff;
  @include module_background($menu-bg-color);
  margin-bottom: 20px;
  padding: 20px 20px 10px 20px;
}

.menu-selection {
  cursor: pointer;
  opacity: 0.62;
  padding: 10px 20px;
}

.menu-selection:hover {
  opacity: 1.0;
}

#fullscreen-button {
  z-index: $ui-z-index;
  bottom: 0;
  right: 0;
}

#login-button {
  width: 215px;
  margin-left: auto;
  margin-right: auto;
}

.event {
  font-family: monospace;
  font-size: 10pt;
}

.status {
  font-family: monospace;
  font-size: 10pt;
}

#menu-backdrop {
  z-index: $menu-backdrop-z-index;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 0;
  padding: 0 0;
  background: #000000dd;
}

#menu-body {
  z-index: $menu-z-index;
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
}

#menu-body a {
  color: #ffffff;
}

.hero {
  p {
    font-family: monospace;
    font-size: 10pt;
    margin-bottom: 0.38rem;
    a {
      color: #ffffff;
    }
  }
}

.content {
  font-family: monospace;
  font-size: 10pt;
  padding-left: 1em;
  padding-right: 1em;

  h1, h2, h3, h4, h5, h6, p, li {
    margin-bottom: 0.62rem;
    text-align: left;
  }

  a, a:hover, a:active, a:visited {
    color: #2bff72;
  }

  ul {
    padding-left: 1.62em;
    padding-right: 1.62em;
  }

  li {
    padding-left: 0;
  }

  img {
    width: 100%;
    height: auto;
  }

  @media only screen and (min-width: $screen-sm) {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    width: 480px;
  }
}

.space-above {
  margin-top: 20px;
}

